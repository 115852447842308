import * as React from "react"
import styled from '@emotion/styled'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout/Layout';
import { PageTitleHeader, Breakpoints } from '../globalStyles';

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
`

const AboutContainer = styled.div`
  .gatsby-image-wrapper{
    float: left;
    width: 50%;
    max-width: 300px;
    margin-right: 10px;
  }
`

const About = () => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return(
  <Layout
    title="Get to Know About Us"
    description="Interested in getting to know more about Blissful Crumbs and origins? Learn about it here!"
    pathname={pathname}
  >
    <Container>
      <PageTitleHeader> About </PageTitleHeader>

      <AboutContainer>

        <StaticImage
          src="../images/toronto-blissful-crumbs-farhana.jpeg"
          alt="Blissful Crumbs cake designer assembling 4 tier wedding cake with handmade roses"
          placeholder="blurred"
          layout="constrained"
          quality={85}
          className="desktop"
        />

        <p>
          My name’s Farhana, and I’m the cake designer at Blissful Crumbs.
        </p>

        <p>
          I loved baking ever since I was a little girl. Every holiday, my sister and I would bake something special together.
          I remember one very exciting Eid holiday, my sister and I made a 3-tier cake to share with friends and family.
          Everybody was very impressed!
        </p>

        <p>
          Over the years, my hobby continued to grow.
          I always had an artistic and creative side, which drew me into binge watching Cake Boss and the Next Great Baker every night.
          One day I was given the opportunity to exercise my baking and artistic skills.
          I was asked to make a cake for an auction—the cake sold for over a thousand dollars!
          It turned out that I had a talent for making beautifully crafted cakes.
        </p>

        <p>
          In 2016, after making cakes for friends and family, I decided to branch out and take 1-2 cakes a week.
          Balancing life as a full-time teacher and a cake designer was difficult, but baking isn’t just a job for me, it’s my passion.
          I believe my passion for making beautifully crafted cakes is reflected in the quality of my work.
        </p>

        <p>
          After much appreciated positive feedback from clients and friends I am proud to say that I have crafted my cakes to perfection.
          I have by the finest quality ingredients and make all of my cake batters in small batches to ensure the best quality.
          I work tirelessly to offer my clients the best and strive to extend beyond limitations by creating many types of flavours and designs.
          At Blissful Crumbs, quality does not start with the beauty of a cake, rather it starts with the taste and ends with beauty.
        </p>
        
        <p>
          I look forward to working with you, to help and perfect the cake of your dreams!
        </p>

      </AboutContainer>

      </Container>
  
    </Layout>
  )
}

export default About

